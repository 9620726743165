import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const LoadingBarIndicatorStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: 100%;
  height: 3px;
  background-color: ${theme.base.color.primary};
  animation-name: animate-progress-bar;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  z-index: 1;

  &.LoadingBarIndicator_isUnderContainer {
    top: auto;
    bottom: 0;
  }
  @-moz-keyframes animate-progress-bar {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @-webkit-keyframes animate-progress-bar {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @-o-keyframes animate-progress-bar {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @keyframes animate-progress-bar {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`);
