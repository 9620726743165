import cn from 'classnames';
import * as React from 'react';
import { useTheme } from 'src/ui-kit/theme';
import Checkbox from '../Checkbox/Checkbox';
import { CheckboxWithLabelStyled } from './CheckboxWithLabel.styles';

export interface CheckboxWithLabelProps {
  checked?: boolean;
  disabled?: boolean;
  labelId: string;
  dataTestId?: string;
  children: React.ReactNode;
  onClick(): void;
  fullWidth?: boolean;
  iconPosition?: 'top' | 'middle';
  dataMarkerValue?: string;
}

export default function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  const { checked, labelId, children, disabled, onClick, dataTestId, fullWidth, iconPosition = 'middle', dataMarkerValue } = props;
  const theme = useTheme();

  const [hovered, setHovered] = React.useState(false);

  return (
    <CheckboxWithLabelStyled
      theme={theme}
      className={cn(
        'CheckboxWithLabel',
        `CheckboxWithLabel_${iconPosition}`,
        {
          'CheckboxWithLabel_disabled': disabled,
          'CheckboxWithLabel__fullWidth': fullWidth,
        },
      )}
    >
      <Checkbox
        labelledby={labelId}
        disabled={disabled}
        hovered={hovered}
        checked={checked}
        onClick={onClick}
        dataTestId={dataTestId}
        dataMarker={dataMarkerValue}
      />

      <label
        onClick={onClick}
        id={labelId}
        aria-disabled={disabled}
        className={cn(
          'CheckboxWithLabel__label', {
          'CheckboxWithLabel__label_fullWidth': fullWidth,
          'CheckboxWithLabel__label_disabled': disabled,
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {children}
      </label>
    </CheckboxWithLabelStyled>
  );
}
