import { UnwrapResponsiveProps } from '../StylishBox/types';
import { Theme } from '../theme/types';
import { GapProps } from './Stack';

const getSpaceValue = (
  gapProps: UnwrapResponsiveProps<GapProps> | undefined,
  themeSpaces: Theme['base']['space'],
) => {
  if (!gapProps) {
    return undefined;
  }

  return themeSpaces[gapProps];
};

export function getGapSBS(gapProps: GapProps | undefined, themeSpaces: any) {
  if (!gapProps) {
    return undefined;
  }

  if (typeof gapProps === 'string') {
    return getSpaceValue(gapProps, themeSpaces);
  }

  return {
    general: getSpaceValue(gapProps.general, themeSpaces),
    desktop: getSpaceValue(gapProps.desktop, themeSpaces),
    desktopTablet: getSpaceValue(gapProps.desktopTablet, themeSpaces),
    tablet: getSpaceValue(gapProps.tablet, themeSpaces),
    tabletPhone: getSpaceValue(gapProps.tabletPhone, themeSpaces),
    phone: getSpaceValue(gapProps.phone, themeSpaces),
  };
}
