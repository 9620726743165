import { LoadingRingStyled } from './LoadingRing.styles';

interface Props {
  color?: string;
  size?: string;
  thickness?: string;
}

function LoadingRing(props: Props) {
  const { color = 'white', size = '30', thickness = '4' } = props;

  return (
    <LoadingRingStyled
      stroke={color}
      size={size}
      className='LoadingRing'
      data-testid='LoadingRing'
    >
      <svg className='LoadingRing__circularLoader' viewBox='25 25 50 50'>
        <circle
          className='LoadingRing__loaderPath'
          cx='50'
          cy='50'
          r='20'
          fill='none'
          stroke={color}
          strokeWidth={thickness}
        />
      </svg>
    </LoadingRingStyled>
  );
}

export default LoadingRing;
