import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { desktop, phone, phone_tablet, tablet, tablet_desktop } from '../../styles/media';
import css from './Responsive.styles';

const mediaQueryMap = {
  desktop: desktop,
  tablet: tablet,
  phone: phone,
  tabletDesktop: tablet_desktop,
  phoneTablet: phone_tablet,
};

interface Props {
  displayOn: keyof typeof mediaQueryMap;
  children: React.ReactNode;
  displayNoneMode?: boolean;
}

const Responsive = (props: Props) => {
  const { displayOn, children, displayNoneMode } = props;
  const isRenderedOnSsr = (displayOn === 'phone' || displayOn === 'phoneTablet');

  const mediaQueryIsObserved = useMediaQuery({ query: mediaQueryMap[displayOn] });
  const [shouldBeRender, setShouldBeRender] = useState(isRenderedOnSsr);

  const displayOnDesktop = displayOn === 'desktop';
  const displayOnTablet = displayOn === 'tablet';
  const displayOnPhone = displayOn === 'phone';
  const displayOnTabletDesktop = displayOn === 'tabletDesktop';
  const displayOnPhoneTablet = displayOn === 'phoneTablet';

  //to avoid hydration errors
  useEffect(() => {
    setShouldBeRender(mediaQueryIsObserved);
  }, [mediaQueryIsObserved]);

  if (displayNoneMode) {
    return (
      <Fragment>
        <div
          className={classNames(
            'Responsive', {
            'Responsive_desktop': displayOnDesktop,
            'Responsive_tablet': displayOnTablet,
            'Responsive_phone': displayOnPhone,
            'Responsive_tabletDesktop': displayOnTabletDesktop,
            'Responsive_phoneTablet': displayOnPhoneTablet,
          })}
          data-marker={classNames({
            'Responsive_desktop': displayOnDesktop,
            'Responsive_tablet': displayOnTablet,
            'Responsive_phone': displayOnPhone,
            'Responsive_tablet_desktop': displayOnTabletDesktop,
            'Responsive_phone_tablet': displayOnPhoneTablet,
          })}
        >
          {children}
        </div>

        <style jsx>{css}</style>
      </Fragment>
    );
  }

  if(shouldBeRender) {
    return (
      <div
          data-marker={classNames({
            'Responsive_desktop': displayOnDesktop,
            'Responsive_tablet': displayOnTablet,
            'Responsive_phone': displayOnPhone,
            'Responsive_tablet_desktop': displayOnTabletDesktop,
            'Responsive_phone_tablet': displayOnPhoneTablet,
          })}
        >
        {props.children}
      </div>
    );
  }

  return null;
};

export default Responsive;
