import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';
import useSWR from 'swr';
import { fetcher } from './fetch';
import { RetailChain } from './types';
import { getChainsUrl } from './urls';

const emptyArray = [];

interface Params {
  fallbackData?: RetailChain[];
  language?: string;
}

export const useChains = (params: Params = {}) => {
  const {
    fallbackData = [],
    language = null,
  } = params;

  const swrFetcher = async ([language]: string[]) => {
    const res = await fetcher<RetailChain[]>(getChainsUrl(), {
      language,
    });

    return getDataFromResponse(res, []);
  };

  const { data, error } = useSWR<RetailChain[]>([language, 'retailChains'], swrFetcher, { fallbackData });

  return {
    chains: data || emptyArray,
    chainsAreFetching: !data && !error,
  };
};
