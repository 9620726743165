import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import { InputStyled } from './Input.styles';

export interface InputProps extends Omit<ComponentProps<'input'>,  'className' | 'ref'> {
  dataMarker?: string;
  testId?: string;
  error?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    dataMarker,
    testId,
    error,
    ...restProps
  } = props;

  const theme = useTheme();

  return (
    <InputStyled
      theme={theme}
      className='Input'
    >
      <input
        type={type}
        className={cn('Input__field', {
          'Input__field_error': !!error,
        })}
        data-marker={ dataMarker }
        data-testid={ testId }
        ref={ref}
        { ...restProps }
      />
      {error && (
        <div className="Input__errorMessage" data-testid='input-error-message'>
          {error}
        </div>
      )}
    </InputStyled>
  );
});

Input.displayName = 'Input';

export default Input;
