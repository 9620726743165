import css from 'styled-jsx/css';
import {
  desktop, phone,
  phone_tablet, tablet_desktop,
} from '../../styles/media';

export default css`

  @media ${desktop} {
    .Responsive_tablet,
    .Responsive_phoneTablet {
      display: none;
    }
  }

  @media ${tablet_desktop} {
    .Responsive_phone {
      display: none;
    }
  }

  @media ${phone_tablet} {
    .Responsive_desktop {
      display: none;
    }
  }

  @media ${phone} {
    .Responsive_tabletDesktop,
    .Responsive_tablet {
      display: none;
    }
  }
`;
