import { CURRENCY } from "src/data/constants";
import useLocalize from "src/services/localize/useLocalize";
import { formatPrice } from "src/utils/formatPrice";

interface Props {
  price: number;
  dataMarker?: string;
}

const Price = (props: Props) => {
  const { price, dataMarker } = props;
  const localize = useLocalize();

  return (
    <span data-marker={dataMarker}>
      {`${formatPrice(price)} ${localize(`general.currency.${CURRENCY}`)}`}
    </span>
  );
};

export default Price;
