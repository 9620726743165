import { Fragment } from "react";

export const personalizeString = (str: string, replacementArray: Array<string | number>): string => {
  return str.replace(/({\d})/g, function (j) {
    return replacementArray[j.replace(/{/, '').replace(/}/, '')];
  });
};

export const personalizeElement = (str: string, replacementArray: Array<JSX.Element>): JSX.Element => {
  const parts = str.split(/{\d+}/g);

  if (parts.length > 1) {
    return (
      <span>{parts.map((item, index) => <Fragment key={index}>{item}{replacementArray[index]}</Fragment>)}</span>
    );

  } else {
    return  <span>{parts[0]}</span>;
  }
};

export default personalizeString;
