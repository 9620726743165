import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme';
import { LoadingBarIndicatorStyled } from './LoadingBarIndicator.styles';

interface Props {
  isUnderContainer?: boolean;
}

function LoadingBarIndicator(props: Props) {
  const theme = useTheme();

  return (
    <LoadingBarIndicatorStyled
      theme={theme}
      data-testid='LoadingBarIndicator'
      data-marker='Loading Bar Indicator'
      className={cn(
        'LoadingBarIndicator', {
        'LoadingBarIndicator_isUnderContainer': props.isUnderContainer,
      })}
    />
  );
}

export default LoadingBarIndicator;
