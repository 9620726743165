import { useServices } from '../../services';
import { ModalsApi } from '../../services/modals/types';

const useModals = (): ModalsApi => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useModals');
  }

  return services.modals;
};

export default useModals;
