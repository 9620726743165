import { useServices } from "..";

const useUserProfile = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useLang');
  }

  return services.userProfile;
};

export default useUserProfile;
